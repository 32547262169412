import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import { store } from "./store/store";
import PersistProvider from "./store/providers/persist-provider";
// import { setProducts } from "./store/slices/product-slice";
// import products from "./data/products.json";
import "animate.css";
import "swiper/swiper-bundle.min.css";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "./assets/scss/style.scss";
import "./i18n";
import CookiesModal from "./components/CookiesModal";
import { logErrorToSlack } from "./utils/logError";

// store.dispatch(setProducts(products));

// Global Error Handler for uncaught errors
window.onerror = (message, source, lineno, colno, error) => {
  const errorDetails = {
    message,
    source,
    lineno,
    colno,
    error: error?.stack || error,
    userAgent: navigator.userAgent,
    url: window.location.href,
  };
  logErrorToSlack(errorDetails);
};

// Global Error Handler for unhandled promise rejections
window.onunhandledrejection = (event) => {
  const errorDetails = {
    message: "Unhandled Promise Rejection",
    reason: event.reason?.stack || event.reason,
    userAgent: navigator.userAgent,
    url: window.location.href,
  };
  logErrorToSlack(errorDetails);
};

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <>
    <Provider store={store}>
      <CookiesModal>
        <PersistProvider>
          <App />
        </PersistProvider>
      </CookiesModal>
    </Provider>
  </>
);
