import axios from "axios";

export async function logErrorToSlack(errorDetails) {
  const apiEndpoint = "/system/crash-log"; // Adjust the endpoint as needed.

  try {
    await axios.post(apiEndpoint, {
      error: errorDetails,
    });
    console.info("Error logged successfully.");
  } catch (err) {
    console.error("Failed to log error:", err);
  }
}
